html {
  background-color: #030508;
}

body {
  margin: 0;
  font-family: 'Roobert TRIAL', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(50% 16.29% at 50% 0%, #150F1F 0%, #030508 100%);
}

* {
  font-family: 'Roobert TRIAL', Helvetica, Arial, sans-serif;
}